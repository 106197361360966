/**
 * @generated SignedSource<<302719cb574334e3c3701adfa0c999fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type HomeInstagramQuery$variables = {
  bannerId: string;
  includeBanner: boolean;
  instagramId: string;
  pageName: string;
};
export type HomeInstagramQuery$data = {
  readonly banner?: {
    readonly contentType: string;
    readonly height: number | null | undefined;
    readonly id: string;
    readonly playlistUrl: string | null | undefined;
    readonly width: number | null | undefined;
  } | null | undefined;
  readonly instagramAccount: {
    readonly media: ReadonlyArray<{
      readonly id: string;
      readonly permalink: string;
      readonly timestamp: any;
    }>;
  } | null | undefined;
  readonly page: {
    readonly banners: ReadonlyArray<{
      readonly contentType: string;
      readonly height: number | null | undefined;
      readonly id: string;
      readonly playlistUrl: string | null | undefined;
      readonly width: number | null | undefined;
    }>;
    readonly content: string | null | undefined;
    readonly title: string | null | undefined;
  } | null | undefined;
};
export type HomeInstagramQuery = {
  response: HomeInstagramQuery$data;
  variables: HomeInstagramQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bannerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeBanner"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "instagramId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageName"
},
v4 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "namePath.0",
        "variableName": "pageName"
      }
    ],
    "kind": "ListValue",
    "name": "namePath"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playlistUrl",
  "storageKey": null
},
v12 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/)
],
v13 = {
  "alias": "banners",
  "args": [
    {
      "kind": "Literal",
      "name": "banner",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "ready",
      "value": true
    }
  ],
  "concreteType": "PageFile",
  "kind": "LinkedField",
  "name": "files",
  "plural": true,
  "selections": (v12/*: any*/),
  "storageKey": "files(banner:true,ready:true)"
},
v14 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "instagramId",
      "variableName": "instagramId"
    }
  ],
  "concreteType": "InstagramAccount",
  "kind": "LinkedField",
  "name": "instagramAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InstagramMedia",
      "kind": "LinkedField",
      "name": "media",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "permalink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timestamp",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "bannerId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeInstagramQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Page",
        "kind": "LinkedField",
        "name": "page",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v13/*: any*/)
        ],
        "storageKey": null
      },
      (v14/*: any*/),
      {
        "condition": "includeBanner",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "banner",
            "args": (v15/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "file",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HomeInstagramQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Page",
        "kind": "LinkedField",
        "name": "page",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v13/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      (v14/*: any*/),
      {
        "condition": "includeBanner",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "banner",
            "args": (v15/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "file",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "afb92ec77e66f14e2a3aad5f129250ad",
    "id": null,
    "metadata": {},
    "name": "HomeInstagramQuery",
    "operationKind": "query",
    "text": "query HomeInstagramQuery(\n  $instagramId: String!\n  $pageName: String!\n  $includeBanner: Boolean!\n  $bannerId: ID!\n) {\n  page(namePath: [$pageName]) {\n    title\n    content\n    banners: files(banner: true, ready: true) {\n      id\n      contentType\n      width\n      height\n      playlistUrl\n    }\n    id\n  }\n  instagramAccount(instagramId: $instagramId) {\n    media {\n      id\n      permalink\n      timestamp\n    }\n  }\n  banner: file(id: $bannerId) @include(if: $includeBanner) {\n    __typename\n    id\n    contentType\n    width\n    height\n    playlistUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee06ae9204c10985a97d6788753ee63d";

export default node;
